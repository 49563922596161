import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import ElementUI from 'element-ui'
import './assets/theme/index.css'
import 'normalize.css'
import './assets/css/bts.less'
import './assets/css/mine.less'
//单个图片上传
import ImgBigUpload from '@/components/ImgBigUpload'
import Editorbar from '@/components/Editorbar'
Vue.component('ImgBigUpload', ImgBigUpload)
Vue.component('Editorbar', Editorbar)
Vue.config.productionTip = false
Vue.use(ElementUI)

new Vue({
  router,
  store,
  render: (h) => h(App)
}).$mount('#app')

import request from "@/utils/request"

// 图片、文件上传
export function uploadAdmin(data) {
  return request({
    url: "/upload/uploadOneFile",
    method: "post",
    data
  })
}

//codeType查询表
export function getCode(data) {
  return request({
    url: '/code/getCode',
    method: 'get',
    params: data
  })
}

// 查询城市
export function getCityAll(data) {
  return request({
    url: '/china/getCityAll',
    method: 'get',
    params: data
  })
}

//文件上传签名
export function uploadsign() {
  return request({
    url: '/upload/sign',
    method: 'get',
  })
}

//单文件下载
export function downLoadFile(query) {
  return request({
    url: "/upload/downloadFile",
    method: "get",
    responseType: "blob",
    params: query
  })
}




import RouterView from '@/components/RouterView'

const menuList = [{
    path: '/workbench',
    name: 'workbench',
    component: RouterView,
    redirect: '/workbench/index',

    meta: {
      title: '工作台',
      isMenu: true
    },
    children: [{
        path: '/workbench/index',
        component: () => import('../views/workbench/index'),
        // meta: {
        //   title: '工作台',
        //   isMenu: true
        // }
      },

    ]

  },
  {
    path: '/userment',
    name: 'Userment',
    component: RouterView,
    redirect: '/userment/index',
    meta: {
      title: '用户管理',
      isMenu: true
    },
    children: [{
        name: 'usercenter',
        path: '/userment/index',
        component: () => import('../views/userment/index'),
        meta: {
          title: '用户中心',
          isMenu: true
        }
      },
      {
        name: 'userinfo',
        path: '/userment/userinfo',
        component: () => import('../views/userment/components/userinfo'),
        meta: {
          title: '用户中心',
          isMenu: true
        }
      },
      {
        path: '/userment/auditment',
        component: () => import('../views/userment/auditment'),
        meta: {
          title: '审核管理',
          isMenu: true
        }
      },
      {
        name: 'Membership',
        path: '/membership/review',
        component: () => import('../views/membership/review'),
        meta: {
          title: '入会审核',
          isMenu: true
        },
      },
      {
        name: 'Detailed',
        path: '/membership/review/detailed',
        meta: {
          title: '入会详情',
          isMenu: true
        },
        component: () => import('../views/membership/review/detailed.vue'),
      }
    ]
  },
  {
    path: '/datament',
    name: 'Datament',
    component: RouterView,
    redirect: '/datament/index',
    meta: {
      title: '数据管理',
      isMenu: true
    },
    children: [{
        name: 'EnterpriseCenter',
        path: '/datament/index',
        component: () => import('../views/datament/index'),
        meta: {
          title: '企业中心',
          isMenu: true
        }
      },
      {
        name: 'addenterprise',
        path: '/datament/addenterprise',
        component: () => import('../views/datament/components/addenterprise'),
        meta: {
          title: '企业中心',
          isMenu: true
        }
      },
      {
        name: 'associated',
        path: '/datament/associated',
        component: () => import('../views/datament/associated'),
        meta: {
          title: '关联项目',
          isMenu: true
        }
      }, {
        name: 'CorrelateUser',
        path: '/datament/CorrelateUser',
        component: () => import('../views/datament/components/CorrelateUser'),
        meta: {
          title: '关联用户',
          isMenu: true
        }
      },
      {
        name: 'ChamberCenter',
        path: '/datament/chambercenter',
        component: () => import('../views/datament/chambercenter'),
        meta: {
          title: '商会中心',
          isMenu: true
        }
      },
      {
        name: 'Configure',
        path: '/datament/chambercenter/configure',
        component: () => import('../views/datament/configure'),
        meta: {
          title: '商会中心',
          isMenu: true
        }
      },
      {
        name: 'addchamber',
        path: '/datament/addchamber',
        component: () => import('../views/datament/components/addchamber'),
        meta: {
          title: '商会中心',
          isMenu: true
        }
      },
      {
        name: 'AssociatedItems',
        path: '/datament/AssociatedItems',
        component: () => import('../views/datament/components/AssociatedItems'),
        meta: {
          title: '商会中心',
          isMenu: true
        }
      },
      {
        name: 'Collections',
        path: '/datament/Collections',
        component: () => import('../views/datament/components/Collections'),
        meta: {
          title: '商会中心',
          isMenu: true
        }
      },
      {
        name: 'ArticleCenter',
        path: '/datament/articlecenter',
        component: () => import('../views/datament/articlecenter'),
        meta: {
          title: '文章中心',
          isMenu: true
        }
      },
      {
        name: 'addarticle',
        path: '/datament/addarticle',
        component: () => import('../views/datament/components/addarticle'),
        meta: {
          title: '文章中心',
          isMenu: true
        }
      }
    ]
  },
  {
    path: '/projectcenter',
    name: 'ProjectCenter',
    component: RouterView,
    redirect: '/projectcenter/activecenter',
    meta: {
      title: '运营管理',
      isMenu: true
    },
    children: [{
        path: '/projectcenter/activecenter',
        name: 'ActiveCenter',
        component: RouterView,
        // redirect: '/projectcenter/list',
        meta: {
          title: '活动中心',
          isMenu: true
        },
        children: [{
            path: '/projectcenter/activecenter',
            component: () => import('../views/ProjectCenter/pageList/activeCenter/index.vue')
          },
          {
            name: 'EditActive',
            path: '/activity/editactivity',
            component: () => import('../views/ProjectCenter/pageList/activeCenter/components/editActivity.vue'),
            meta: {
              title: '编辑活动',
              isMenu: true
            }
          }, {
            name: 'accountManagement',
            path: '/activity/accountmanagement',
            component: () => import('../views/ProjectCenter/pageList/activeCenter/components/accountManagement.vue'),
            meta: {
              title: '报名管理',
              isMenu: true
            }
          }, {
            name: 'arrangeSeats',
            path: '/activity/arrangeseats',
            component: () => import('../views/ProjectCenter/pageList/activeCenter/components/arrangeSeats.vue'),
            meta: {
              title: '安排座位',
              isMenu: true
            }
          }


        ]
      },
      {
        path: '/projectcenter/project',
        name: 'Investigate',
        component: RouterView,
        // redirect: '/projectcenter/list',
        meta: {
          title: '项目中心',
          isMenu: true
        },
        children: [{
            path: '/projectcenter/project',
            component: () => import('../views/ProjectCenter/index')
          },
          {
            name: 'addinvestigate',
            path: '/projectcenter/addinvestigate',
            component: () => import('../views/ProjectCenter/components/addinvestigate'),
            meta: {
              title: '新增项目',
              isMenu: true
            }
          },
          {
            name: 'ConfigureEnterprise',
            path: '/projectcenter/ConfigureEnterprise',
            component: () => import('../views/ProjectCenter/components/ConfigureEnterprise'),
            meta: {
              title: '配置企业',
              isMenu: true
            }
          },
          {
            name: 'ConfiguringReports',
            path: '/projectcenter/ConfiguringReports',
            component: () => import('../views/ProjectCenter/components/ConfiguringReports'),
            meta: {
              title: '配置报告',
              isMenu: true
            }
          },
          {
            name: 'TravelConfiguration',
            path: '/projectcenter/TravelConfiguration',
            component: () => import('../views/ProjectCenter/components/TravelConfiguration'),
            meta: {
              title: '行程管理',
              isMenu: true
            }
          },
          {
            name: 'addtrip',
            path: '/projectcenter/TravelConfiguration/addtrip',
            component: () => import('../views/ProjectCenter/components/addtrip'),
            meta: {
              title: '行程管理',
              isMenu: true
            }
          },
          {
            name: 'Journeyrecord',
            path: '/projectcenter/Journeyrecord',
            component: () => import('../views/ProjectCenter/components/Journeyrecord'),
            meta: {
              title: '行程记录',
              isMenu: true
            }
          },
          {
            name: 'visitingCompany',
            path: '/projectcenter/visitingCompany',
            component: () => import('../views/ProjectCenter/visitingCompany'),
            meta: {
              title: '参访企业',
              isMenu: true
            }
          },
          {
            name: 'contactUs',
            path: '/projectcenter/contactUs',
            component: () => import('../views/ProjectCenter/contactUs'),
            meta: {
              title: '需要联系的客户',
              isMenu: true
            }
          },

        ]
      },

      // {
      //   name: 'EnterpriseProject',
      //   path: '/projectcenter/enterpriseproject',
      //   component: () => import('../views/ProjectCenter/enterpriseproject'),
      //   meta: {
      //     title: '企业项目',
      //     isMenu: true
      //   }
      // },
      // {
      //   name: 'Governmentprojects',
      //   path: '/projectcenter/Governmentprojects',
      //   component: () => import('../views/ProjectCenter/Governmentprojects'),
      //   meta: {
      //     title: '政府项目',
      //     isMenu: true
      //   }
      // }
    ]
  },
  {
    path: '/system',
    name: 'System',
    component: RouterView,
    redirect: '/system/reception',
    meta: {
      title: '系统管理',
      isMenu: true
    },
    children: [{
        name: 'Reception',
        path: '/system/reception',
        component: () => import('../views/system/reception'),
        meta: {
          title: '前台管理',
          isMenu: true
        }
      },
      {
        name: 'user',
        path: '/system/user',
        component: () => import('../views/system/user'),
        meta: {
          title: '系统用户',
          isMenu: true
        }
      },
      {
        name: 'Jurisdiction',
        path: '/system/jurisdiction',
        component: () => import('../views/system/jurisdiction'),
        meta: {
          title: '权限管理',
          isMenu: true
        }
      },

    ]
  }
]

export default menuList

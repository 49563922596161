import Vue from 'vue'
import VueRouter from 'vue-router'
import layout from '../layout'
import menuList from './menuList'

Vue.use(VueRouter)

const routes = [
  {
    path: '/login',
    component: () => import('@/layout/components/Login'),
    hidden: true
  },
  {
    path: '/',
    redirect: '/home'
  },
  {
    path: '/home',
    name: 'Home',
    redirect: '/userment',
    component: layout,
    children: menuList
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

router.beforeEach((to, from, next) => {
  const token = sessionStorage.getItem('token')
  if (to.path !== '/login' && token !== null) {
    next()
  } else if (to.path !== '/login' && token === null) {
    next({ path: '/login' })
  } else if (to.path === '/login' && token === null) {
    next()
  } else {
    next({ path: '/' })
  }
  next()
})

const resolveOriginal = VueRouter.prototype.push

VueRouter.prototype.push = function push(location) {
  return resolveOriginal.call(this, location).catch((err) => err)
}

export default router

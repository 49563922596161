<template>
  <div class="hy-header">
    <Breadcrumb />
  </div>
</template>

<script>
import Breadcrumb from '../Breadcrumb'
export default {
  components: {
    Breadcrumb
  }
}
</script>

<style lang="less" scoped>
.hy-header {
  height: 50px;
}
</style>

<template>
  <div>
    <el-menu
      class="h-v"
      :collapse="isCollapse"
      :collapse-transition="false"
      router
      unique-opened
      :default-active="activePath"
    >
      <template v-for="item in menuList">
        <!-- 一级菜单 -->
        <router-link :to="item.path" :key="item.id" v-if="!item.hasOwnProperty('children')">
          <el-menu-item :index="item.path + ''">
            <i :class="item.icon" />
            <span>{{ item.name }}</span>
          </el-menu-item>
        </router-link>
        <el-submenu v-else :key="item.id" :index="item.path + ''">
          <!-- 一级菜单模板区域 -->
          <template slot="title">
            <!-- 图标 -->
            <i :class="item.icon" />
            <!-- 文本 -->
            <span>{{ item.name }}</span>
          </template>
          <!-- 二级菜单 -->
          <el-menu-item v-for="subItem in item.children" :key="subItem.id" :index="subItem.path">
            <template slot="title">
              <!-- 图标 -->
              <!--  <i :class="subItem.meta.icon" />-->
              <!-- 文本 -->
              <span>{{ subItem.name }}</span>
            </template>
          </el-menu-item>
        </el-submenu>
      </template>
    </el-menu>
  </div>
</template>

<script>
export default {
  data() {
    return {
      isCollapse: false,
      activePath: '', // 当前路由
      menuList: [
        {
          id: 0,
          name: '工作台',
          path: '/workbench',
          icon: 'el-icon-s-data'
        },
        {
          id: 1,
          name: '用户管理',
          path: '/userment',
          icon: 'el-icon-s-custom',
          children: [
            {
              id: 11,
              name: '用户中心',
              path: '/userment/index'
            },
            {
              id: 12,
              name: '审核管理',
              path: '/userment/auditment'
            },
            {
              id: 13,
              name: '入会审核',
              path: '/membership/review'
            }
          ]
        },
        {
          id: 2,
          name: '数据管理',
          path: '/datament',
          icon: 'el-icon-s-grid',
          children: [
            {
              id: 21,
              name: '企业中心',
              path: '/datament/index'
            },
            {
              id: 22,
              name: '商会中心',
              path: '/datament/chambercenter'
            },
            {
              id: 23,
              name: '文章中心',
              path: '/datament/articlecenter'
            }
          ]
        },
        {
          id: 3,
          name: '运营管理',
          path: '/projectcenter',
          icon: 'el-icon-s-cooperation',
          children: [
            {
              id: 31,
              name: '活动中心',
              path: '/projectcenter/activecenter'
            },
            // {
            //   id: 31,
            //   name: '课程中心',
            //   path: '/projectcenter/classcenter'
            // },
            {
              id: 33,
              name: '项目中心',
              path: '/projectcenter/project'
            }
            // {
            //   id: 32,
            //   name: '企业项目',
            //   path: '/projectcenter/enterpriseproject'
            // },
            // {
            //   id: 33,
            //   name: '政府项目',
            //   path: '/projectcenter/Governmentprojects'
            // }
          ]
        },
        {
          id: 4,
          name: '系统管理',
          path: '/system',
          icon: 'el-icon-s-tools',
          children: [
            // {
            //   id: 41,
            //   name: '前台管理',
            //   path: '/system/reception',
            // },
            {
              id: 42,
              name: '系统用户',
              path: '/system/user'
            }
            // {
            //   id: 43,
            //   name: '权限管理',
            //   path: '/system/jurisdiction',
            // }
          ]
        }
      ]
    }
  }
}
</script>

<style lang="less" scoped>
::v-deep .el-menu {
  border-right: solid 1px #ffffff;
  list-style: none;
  position: relative;
  margin: 0;
  padding-left: 0;
  background-color: #fff;
}
</style>

<template>
  <div>
    <div class="app_head">
      <div class="txt_name">
       <img src="../assets/image/数智秘书长运营后台.png" alt="">
      </div>
      <div v-if="token" style="padding-right: 30px" @click="logout">
        <span>退出登录</span>
      </div>
    </div>
    <el-container class="h-v">
      <el-aside width="200px">
        <Aside />
      </el-aside>
      <el-container>
        <el-header style="height:50px">
          <hy-header></hy-header>
        </el-header>
        <el-main class="hy-main">
          <router-view />
        </el-main>
      </el-container>
    </el-container>
  </div>
</template>

<script>
import RouterView from '../components/RouterView.vue'
import Aside from './components/Aside'
import HyHeader from './components/HyHeader'
export default {
  components: { RouterView, Aside, HyHeader },

  data() {
    return {
      token: sessionStorage.getItem('token')
    }
  },

  methods: {
    logout() {
      this.$confirm('您确定退出系统吗？', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(() => {
          sessionStorage.removeItem('token')
          location.href = '/login'
        })
        .catch(() => {})
    }
  }
}
</script>

<style lang="less" scoped>

::v-deep .el-container {
  display: flex;
  flex-direction: row;
  flex: 1;
  flex-basis: auto;
  box-sizing: border-box;
  min-width: 0;
  background: #f9f9f9;
}
::v-deep .h-v {
    height: 93vh;
}
.hy-main {
  background: #f9f9f9;
}
::v-deep .el-main {
  display: block;
  flex: 1;
  flex-basis: auto;


  box-sizing: border-box;
  padding: 0px 20px 20px 20px;
}
.app_head {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;

  .txt_name {
    padding: 15px 20px;
    img{
      width: 256px;
      height: 28px;
    }
  }
}
</style>

<template>
  <div class="breadcrumb">
    <el-button type="text" icon="jr-iconjr-icon-back" />
    <el-breadcrumb separator="/">
      <template v-for="(item, i) in levelList">
        <el-breadcrumb-item
          v-if="item.meta.title"
          :key="item.path"
          class="breadcrumb-item"
          :to="getPath(item.path)"
        >
          {{
            $route.params.paramsName && i === 2
              ? '' + $route.params.paramsName + ' ' + item.meta.title
              : item.meta.title
          }}
        </el-breadcrumb-item>
      </template>
    </el-breadcrumb>
  </div>
</template>

<script>
export default {
  name: 'Breadcrumb',

  props: {
    isCollapse: {
      type: Boolean,
      default: true
    }
  },

  data() {
    return {
      levelList: []
    }
  },

  computed: {
    getPath() {
      return function (path) {
        // if (path === '/businessData') return false

        let pathlocal = path

        if (pathlocal.indexOf(':') !== -1 && this.$route.params) {
          const paramsIndex = pathlocal.indexOf(':')

          const paramsStr = pathlocal.substring(paramsIndex).substring(1)

          pathlocal = pathlocal.substring(0, paramsIndex)

          pathlocal = pathlocal + this.$route.params[paramsStr]

          if (path !== pathlocal) {
            return pathlocal
          } else {
            return path
          }
        } else {
          return path
        }
      }
    }
  },
  watch: {
    $route() {
      this.getBreadcrumb()
    }
  },
  created() {
    this.getBreadcrumb()
  },
  methods: {
    // 切换侧边栏展开状态
    toggleCollapse() {
      this.$emit('update:isCollapse', !this.isCollapse)
    },
    // 动态面包屑
    getBreadcrumb() {
      const matched = this.$route.matched.filter((item) => item.name)

      this.levelList = matched
    }
  }
}
</script>

<style lang="less" scoped>
.breadcrumb {
  height: 50px;
  display: flex;
  align-items: center;
  .el-breadcrumb {
    font-size: 14px;
    color: #252631;
  }
  
}
::v-deep  .el-breadcrumb__inner.is-link {
    font-weight: 500;
    text-decoration: none;
    transition: color .2s cubic-bezier(.645, .045, .355, 1);
    color: #303133;
}
::v-deep .el-breadcrumb__item:last-child .el-breadcrumb__inner, .el-breadcrumb__item:last-child .el-breadcrumb__inner a, .el-breadcrumb__item:last-child .el-breadcrumb__inner a:hover, .el-breadcrumb__item:last-child .el-breadcrumb__inner:hover {
    font-weight: 400;
    color: #4d8efe;
    cursor: text;
}
</style>
